import styles from "./Logo.module.scss";
import P from "prop-types";

export const Logo = ({ className }) => (
    <div className={`${styles["widget-logo"]} ${className}`}>
        <img
            alt="logo doutor123"
            src="/images/logo-doutor123.png"
            onClick={() => (window.location = "/#")}
        />
    </div>
);

Logo.propTypes = {
    className: P.string,
};

Logo.defaultProps = {
    className: "",
};
