import React, { useEffect } from "react";
import { Route, Routes, Link } from "react-router-dom";

import Home from "./templates/Home";
import Company from "./templates/Company";
import SaudePraVoce from "./templates/SaudePraVoce";
import Profissionais from "./templates/Profissionais";

import { Politics } from "./templates/Institutional/Politics";
import { TermsAndConditions } from "./templates/Institutional/TermsAndConditions";

import { useLocation } from "react-router-dom";
import { appConfig } from "./config";

const NoMatch = () => (window.location = "/");

const App = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path="saude-pra-voce" element={<SaudePraVoce />} />
            <Route path="para-empresas" element={<Company />} />
            <Route path="termos-de-uso" element={<TermsAndConditions />} />
            <Route path="politicas-de-privacidade" element={<Politics />} />
            <Route path="para-profissionais" element={<Profissionais />} />
            <Route path="para-profissionais-bayer" element={<Profissionais origin={'pagina profissionais-bayer'} />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
};

export default App;
